import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import Energy from "../subtab/energy/energy";
import Solor from "../subtab/solor/solor";
import Heat from "../subtab/heat/heat";
import Water from "../subtab/water/water";
import Ventilation from "../subtab/ventilation/ventilation";
import { getAllChartData } from "../../actions/list-view";
import {
  getMemberDetailsForSite,
  clearProjectMemberData,
  clearProjectData,
  clearNoInternet,
} from "../../actions/list-view";
import LoaderRoller from "../../components/loader/LoaderRoller";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { styled } from "@mui/material/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar } from "@fortawesome/pro-regular-svg-icons";
import LastSeenStatus from "../chart/lastSeenStatus";
import Homechartfilter from "../chart/homechartfilter";
import moment from "moment";
import Battery from "../subtab/battery/battery";

class Monitor extends Component {
  state = {
    formInput: {
      groupId: "",
      hoveredItem: 0,
      monitorDetails: [],
      subTabName: "",
      noInternetView: true,
    },
    isMobile: window.innerWidth < 768,
    isMobileFilterPopupVisible: false,
  };
  filterPopup = () => {
    this.setState({ isMobileFilterPopupVisible: true }); // Show the mobile filter popup
  };

  closeFilterPopup = () => {
    this.setState({ isMobileFilterPopupVisible: false }); // Hide the mobile filter popup
  };
  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize);

    const devUrls = [
      "https://evalan-dev-wattmaestro-frontend.azurewebsites.net/",
    ];
    const currentUrl = window.location.href;
    if (devUrls.some((devUrl) => currentUrl.startsWith(devUrl))) {
      document.title = "🚧 WattMaestro - Equipment";
    } else {
      document.title = "WattMaestro - Equipment";
    }
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    this.props.clearProjectMemberData();
    this.props.clearProjectData();
    this.props.getMemberDetailsForSite(groupId);

    //to reset graph data on the equipment page
    let dataView = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    let combinedDatasets = [];
    let dataviewList = dataView[0].latestSummary;
    var date = new Date();
    let start = date.setHours(0, 0, 0, 0);
    let startdate = start / 1000;
    let endDateMLSec = date.setHours(24, 0, 0, 0) + 3600000;
    let enddate = endDateMLSec / 1000;
    let id_container_data_latest = {
      "filter[timestamp_seconds][gte]": startdate,
      "filter[timestamp_seconds][lte]": enddate,
    };
    Object.keys(dataviewList).forEach((key) => {
      const dataCategory = dataviewList[key].data_category;
      if (
        dataCategory === "SplitEnergyMeterPowerNegative" ||
        dataCategory === "SplitEnergyMeterPowerPositive"
      ) {
        const datasetName = dataviewList[key].datasets;
        combinedDatasets = combinedDatasets.concat(datasetName);
      }
      if (
        dataCategory === "ActiveImportEnergy" ||
        dataCategory === "ActiveExportEnergy" ||
        dataCategory === "EnergyPrice"
      ) {
        const datasetName = dataviewList[key].datasets;
        combinedDatasets = combinedDatasets.concat(datasetName);
      }
    });
    combinedDatasets.forEach((dataset, idx) => {
      id_container_data_latest[`filter[id_container_data_latest][${idx}]`] =
        dataset;
    });
    let params = Object.entries(id_container_data_latest)
      .filter((e) => e[1] !== undefined)
      .map((e) => {
        return e.join("=");
      })
      .join("&");
    formInput.filterOptions = params;
    this.setState({ formInput });
    this.props.clearNoInternet();
    this.props.getAllChartData(params);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  calculateTimeAgo = (lastDataTimestamp) => {
    if (isNaN(lastDataTimestamp)) {
      return "Invalid date";
    }

    const currentTimestamp = Date.now();
    const duration = moment.duration(currentTimestamp - lastDataTimestamp);

    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths());
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds());

    if (years > 0) {
      return `${years} ${years === 1 ? "YEAR" : "YEARS"} AGO`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? "MONTH" : "MONTHS"} AGO`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? "DAY" : "DAYS"} AGO`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? "HR" : "HRS"} AGO`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? "MIN" : "MINS"} AGO`;
    } else {
      return `${seconds} ${seconds === 1 ? "SEC" : "SEC"} AGO`;
    }
  };
  handleResize = () => {
    this.setState({ isMobile: window.innerWidth <= 767 });
  };

  handleMouseEnter = (itemId, subTabValue) => {
    const formInput = { ...this.state.formInput };
    formInput.hoveredItem = itemId;
    formInput.subTabName = subTabValue;
    this.setState({ formInput });
  };

  handleMouseLeave = () => {
    this.setState({ hoveredItem: null });
  };

  handleToCloseNoInternet = () => {
    const formInput = { ...this.state.formInput };
    formInput.noInternetView = false;
    this.setState({ formInput });
  };
  render() {
    let { groupId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    const monitorData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const monitorPhysicalData = monitorData[0].physicalDevices.filter(
      (m) => m.id_parent !== null && m.deviceProfile !== null
    );

    formInput.monitorDetails = monitorPhysicalData;
    const BlackOutlinedErrorAlert = styled(Alert)({});
    const lastSeenVal = monitorData[0]?.meta?.last_data_at;
    return (
      <div className="left-navigation mobile-container">
        {this.props.noInternet !== undefined && formInput.noInternetView && (
          <div className="alertLastSeenDialog">
            <BlackOutlinedErrorAlert
              className={`${
                this.props.noInternet !== undefined
                  ? "success-dialog"
                  : "error-dialog"
              }`}
              style={{
                backgroundColor:
                  this.props.noInternet !== undefined ? "#FF9A00" : "#FF5D5D",
              }}
              severity={
                this.props.noInternet !== undefined ? "success" : "error"
              }
              onClose={this.handleToCloseNoInternet}
              iconMapping={{
                success: <ReportProblemOutlinedIcon fontSize="inherit" />,
              }}
            >
              <AlertTitle
                style={{
                  fontFamily: "'Roboto Flex', sans-serif",
                  fontWeight: "600",
                  fontSize: "16px",
                  color: "#272729",
                }}
              >
                {this.props.noInternet !== undefined
                  ? "No Internet"
                  : "Update Error"}
              </AlertTitle>
              {this.props.noInternet !== undefined
                ? "There is no Internet. Please reconnect to keep monitoring your energy equipment."
                : "There is no Internet. Please reconnect to keep monitoring your energy equipment."}
            </BlackOutlinedErrorAlert>
          </div>
        )}
        {this.props.projectMemberDetails !== undefined &&
        monitorPhysicalData[0]?.deviceProfile?.equipmentLabel &&
        this.state.isMobile ? (
          <div class="dropdown mb-2-mobile">
            <button
              class="dropdown-toggle dropdownSiteModeOptionStyle"
              type="button"
              id="dropdownSiteName"
              data-bs-toggle="dropdown"
            >
              {formInput.subTabName
                ? formInput.subTabName
                : monitorPhysicalData[0].deviceProfile?.equipmentLabel}
            </button>

            <div
              class="dropdown-menu dropdownSiteStyle"
              aria-labelledby="dropdownSiteName"
            >
              {monitorPhysicalData.map((m, index) => (
                <button
                  onClick={() =>
                    this.handleMouseEnter(
                      index,
                      m?.deviceProfile?.equipmentLabel === "Solar"
                        ? "Inverter"
                        : m?.deviceProfile?.equipmentLabel
                    )
                  }
                  class="dropdown-item optionValue"
                  value={
                    m?.deviceProfile?.equipmentLabel === "Solar"
                      ? "Inverter"
                      : m?.deviceProfile?.equipmentLabel
                  }
                >
                  {m?.deviceProfile?.equipmentLabel === "Solar"
                    ? "Inverter"
                    : m?.deviceProfile?.equipmentLabel}
                </button>
              ))}
            </div>
            <span className="lastseen-txt mt-2" style={{ paddingLeft: "0px" }}>
              Last Seen:{" "}
              <span className="text-lowercase">
                {this.calculateTimeAgo(lastSeenVal)} &nbsp;
              </span>
            </span>
          </div>
        ) : (
          <div className="tab-view">
            {monitorPhysicalData.map((m, index) => (
              <div
                key={index}
                onClick={() =>
                  this.handleMouseEnter(index, m?.deviceProfile?.equipmentLabel)
                }
              >
                <span
                  className={
                    formInput.hoveredItem === index
                      ? "active-subtab-link over"
                      : "inactive-subtab-link over"
                  }
                >
                  <span>
                    <div className="no-underscore menu-item-text">
                      <span>
                        {m?.deviceProfile?.equipmentLabel === "Solar"
                          ? "Inverter"
                          : m?.deviceProfile?.equipmentLabel}
                      </span>
                    </div>
                  </span>
                </span>
              </div>
            ))}
          </div>
        )}

        <div className="desktopbg">
          {this.props.projectMemberDetails !== undefined ? (
            monitorPhysicalData[0]?.deviceProfile?.equipmentLabel ===
              "Energy Meter" && formInput.hoveredItem === 0 ? (
              <Energy
                modeName="Energy"
                monitorDetails={formInput.monitorDetails}
                projectMemberDetails={this.props.projectMemberDetails}
                filterOptions={formInput.filterOptions}
              />
            ) : monitorPhysicalData[0]?.deviceProfile?.equipmentLabel ===
                "Solar" && formInput.hoveredItem === 0 ? (
              <Solor
                modeName="Inverter"
                monitorDetails={formInput.monitorDetails}
                projectMemberDetails={this.props.projectMemberDetails}
                filterOptions={formInput.filterOptions}
              />
            ) : monitorPhysicalData[0]?.deviceProfile?.equipmentLabel ===
                "Heat Pump" && formInput.hoveredItem === 0 ? (
              <Heat
                modeName="Heat Pump"
                monitorDetails={formInput.monitorDetails}
                filterOptions={formInput.filterOptions}
              />
            ) : monitorPhysicalData[0]?.deviceProfile?.equipmentLabel ===
                "Battery" && formInput.hoveredItem === 0 ? (
              <Battery
                modeName="Battery"
                monitorDetails={formInput.monitorDetails}
                projectMemberDetails={this.props.projectMemberDetails}
                filterOptions={formInput.filterOptions}
              />
            ) : monitorPhysicalData[0]?.deviceProfile?.equipmentLabel ===
                "Water Meter" && formInput.hoveredItem === 0 ? (
              <Water />
            ) : monitorPhysicalData[0]?.deviceProfile?.equipmentLabel ===
                "Ventilation" && formInput.hoveredItem === 0 ? (
              <Ventilation />
            ) : formInput.subTabName === "Energy Meter" ? (
              <Energy
                modeName="Energy"
                monitorDetails={formInput.monitorDetails}
                projectMemberDetails={this.props.projectMemberDetails}
                filterOptions={formInput.filterOptions}
              />
            ) : formInput.subTabName === "Solar" ||
              formInput.subTabName === "Inverter" ? (
              <Solor
                modeName="Inverter"
                monitorDetails={formInput.monitorDetails}
                projectMemberDetails={this.props.projectMemberDetails}
                filterOptions={formInput.filterOptions}
              />
            ) : formInput.subTabName === "Battery" ? (
              <Battery
                modeName="Battery"
                monitorDetails={formInput.monitorDetails}
                projectMemberDetails={this.props.projectMemberDetails}
                allProjectData={this.props.allProjectData}
                filterOptions={formInput.filterOptions}
              />
            ) : formInput.subTabName === "Heat Pump" ? (
              <Heat
                modeName="Heat Pump"
                monitorDetails={formInput.monitorDetails}
                filterOptions={formInput.filterOptions}
              />
            ) : formInput.subTabName === "Water Meter" ? (
              <Water />
            ) : formInput.subTabName === "Ventilation" ? (
              <Ventilation />
            ) : (
              <div>No data found</div>
            )
          ) : (
            <LoaderRoller />
          )}
        </div>
        {monitorPhysicalData[0]?.deviceProfile?.equipmentLabel && (
          <div
            className="sticky-button mt-2"
            style={{ left: "79%", width: "40%" }}
          >
            <div className="d-flex justify-content-around">
              <div className="filter-btn">
                <FontAwesomeIcon
                  className="btn-color fa-lg"
                  icon={faCalendar}
                  onClick={this.filterPopup}
                />
              </div>
              <LastSeenStatus
                isMonitor="Monitor"
                isMobile={true}
                equipmentStatusData={monitorData}
                filterOptions={formInput.filterOptions}
              />
            </div>
          </div>
        )}

        {/* Mobile Filter Popup */}
        {this.state.isMobileFilterPopupVisible && (
          <div className="popup">
            <div>
              <Homechartfilter
                isMobile={true}
                allChartData={this.props.allChartData}
                dataSetsArray={formInput.combinedDatasets}
                chartName={formInput.chartName}
                chartUnit={formInput.chartUnit}
                closePopup={this.closeFilterPopup}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
}
function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    projectMemberDetails: state.listViewReducer?.projectMemberDetails,
    noInternet: state.listViewReducer?.noInternet,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    getMemberDetailsForSite,
    clearProjectData,
    getAllChartData,
    clearProjectMemberData,
    clearNoInternet,
  })(Monitor)
);
