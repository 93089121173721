import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import "./styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoaderRoller from "../../components/loader/LoaderRoller";
import HomeWork from "../../assets/watt-icons/home_work.svg";
import Warning from "../../assets/watt-icons/Warning.svg";
import energyGeneration from "../../assets/watt-icons/energy_generation.svg";
import energyConsumption from "../../assets/watt-icons/energy_consumption.svg";
import { openUserPopup, closeUserPopup } from "../../actions/screen-activities";
import {
  faCircleCheck,
  faTriangleExclamation,
  faTimesCircle,
} from "@fortawesome/pro-regular-svg-icons";
import {} from "@fortawesome/pro-regular-svg-icons";
import Header from "../header/header";
import moment from "moment";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import { styled } from "@mui/material/styles";

import { clearNoInternet } from "../../actions/list-view";

class Sites extends Component {
  //To calaculate the Last seen in the Sites OverView Page
  calculateTimeAgo = (lastDataTimestamp) => {
    if (isNaN(lastDataTimestamp)) {
      return "Invalid date";
    }

    const currentTimestamp = Date.now();
    const duration = moment.duration(currentTimestamp - lastDataTimestamp);

    const years = Math.floor(duration.asYears());
    const months = Math.floor(duration.asMonths());
    const days = Math.floor(duration.asDays());
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes());
    const seconds = Math.floor(duration.asSeconds());

    if (years > 0) {
      return `${years} ${years === 1 ? "year" : "years"} ago`;
    } else if (months > 0) {
      return `${months} ${months === 1 ? "month" : "months"} ago`;
    } else if (days > 0) {
      return `${days} ${days === 1 ? "day" : "days"} ago`;
    } else if (hours > 0) {
      return `${hours} ${hours === 1 ? "hr" : "hrs"} ago`;
    } else if (minutes > 0) {
      return `${minutes} ${minutes === 1 ? "min" : "mins"} ago`;
    } else {
      return `${seconds} ${seconds === 1 ? "sec" : "sec"} ago`;
    }
  };

  state = {
    formInput: {
      changeName: false,
      projectNameConfirm: false,
      projectNameCreate: false,
      projectName: "",
      projectId: "",
      showOption: false,
      createName: false,
      projectNewName: "",
      options: [],
      series: [],
      dataLabels: [],
      totalOnSite: 0,
      totalOffSite: 0,
      totalWarning: 0,
      totalPowerEnergy: 0,
      totalPowerConsumption: 0,
      noInternetView: true,
    },
  };
  componentDidMount = () => {
    const devUrls = [
      "https://evalan-dev-wattmaestro-frontend.azurewebsites.net/",
    ];
    const currentUrl = window.location.href;
    if (devUrls.some((devUrl) => currentUrl.startsWith(devUrl))) {
      document.title = "🚧 WattMaestro - Overview";
    } else {
      document.title = "WattMaestro - Overview";
    }

    let { id } = this.props.match.params;
    if (!id) {
      return this.props.history.push("/");
    }
    const { currentPageNr } = this.props.match.params;
    if (!currentPageNr) {
    }
    this.props.clearNoInternet();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (window.location.pathname.startsWith("/site/home/")) {
      const groupId = window.location.pathname.slice(16);
      this.props.onSelectProject(groupId);
    }
  };

  checkSiteDetails = (idGroup) => {
    this.props.onSelectProject(idGroup);
    this.props.history.push(`/site/home/${idGroup}`);
  };

  handleToCloseNoInternet = () => {
    const formInput = { ...this.state.formInput };
    formInput.noInternetView = false;
    this.setState({ formInput });
  };
  render() {
    const formInput = { ...this.state.formInput };
    var totalEnergy = 0;
    var totalConsumption = 0;
    var totalConnected = 0;
    var totalDisconnected = 0;
    var totalWarn = 0;

    const getWattMaestroModeText = (wattMaestroMode) => {
      switch (String(wattMaestroMode)) {
        case "1":
          return "Monitoring";
        case "2":
          return "Curtailment";
        case "3":
          return "Load Balancing";
        default:
          return "";
      }
    };

    const getBatteryModeText = (batteryMode) => {
      switch (String(batteryMode)) {
        case "1":
          return "Monitoring";
        case "2":
          return "Optimization";
        default:
          return "";
      }
    };
    this.props.projectAllData?.items.forEach((m) => {
      var countConnect = m?.physicalDevices.filter(
        (m) => m?.is_connected === 1
      ).length;
      var countDisconnect = m?.physicalDevices.filter(
        (m) => m?.is_connected === 0
      ).length;
      var isGateway =
        m?.physicalDevices.filter((m) => m?.id_parent === null).length > 0;
      totalEnergy +=
        m?.latestSummary?.SplitEnergyMeterPowerPositive !== undefined
          ? m?.latestSummary?.SplitEnergyMeterPowerPositive?.sum
          : 0;

      totalConsumption +=
        m?.latestSummary?.SplitEnergyMeterPowerNegative?.sum !== undefined
          ? m?.latestSummary?.SplitEnergyMeterPowerNegative?.sum
          : 0;
      if (isGateway) {
        if (countConnect > 0 && countDisconnect > 0) {
          totalWarn = totalWarn + 1;
        } else if (countConnect > 0 && countDisconnect === 0) {
          totalConnected = totalConnected + 1;
        } else if (
          (countConnect === 0 && countDisconnect > 0) ||
          (countConnect === 0 && countDisconnect === 0)
        ) {
          totalDisconnected = totalDisconnected + 1;
        }
      } else {
        totalDisconnected = totalDisconnected + 1;
      }
    });

    formInput.totalPowerEnergy = totalEnergy;
    formInput.totalPowerConsumption = totalConsumption;
    formInput.totalOnSite = totalConnected;
    formInput.totalOffSite = totalDisconnected;
    formInput.totalWarning = totalWarn;
    const BlackOutlinedErrorAlert = styled(Alert)({});
    if (this.props.projectAllData?.items) {
      return (
        <Fragment>
          <div>
            {this.props.noInternet !== undefined &&
              formInput.noInternetView && (
                <div className="alertLastSeenDialog">
                  {" "}
                  <BlackOutlinedErrorAlert
                    className={`${
                      this.props.noInternet !== undefined
                        ? "success-dialog"
                        : "error-dialog"
                    }`}
                    style={{
                      backgroundColor:
                        this.props.noInternet !== undefined
                          ? "#FF9A00"
                          : "#FF5D5D",
                    }}
                    severity={
                      this.props.noInternet !== undefined ? "success" : "error"
                    }
                    onClose={this.handleToCloseNoInternet}
                    iconMapping={{
                      success: <ReportProblemOutlinedIcon fontSize="inherit" />,
                    }}
                  >
                    <AlertTitle
                      style={{
                        fontFamily: "'Roboto Flex', sans-serif",
                        fontWeight: "600",
                        fontSize: "16px",
                        color: "#272729",
                      }}
                    >
                      {this.props.noInternet !== undefined
                        ? "No Internet"
                        : "Update Error"}
                    </AlertTitle>
                    {this.props.noInternet !== undefined
                      ? "There is no Internet. Please reconnect to keep monitoring your energy equipment."
                      : "There is no Internet. Please reconnect to keep monitoring your energy equipment."}
                  </BlackOutlinedErrorAlert>
                </div>
              )}
            {this.props.noInternet !== undefined &&
              formInput.noInternetView && (
                <div className="row alert-last-seen">
                  <div className="alert-last-seen-dialog">
                    <BlackOutlinedErrorAlert
                      className={`${
                        this.props.noInternet !== undefined
                          ? "success-dialog"
                          : "error-dialog"
                      }`}
                      style={{
                        backgroundColor:
                          this.props.noInternet !== undefined
                            ? "#FF9A00"
                            : "#FF5D5D",
                      }}
                      severity={
                        this.props.noInternet !== undefined
                          ? "success"
                          : "error"
                      }
                      onClose={this.handleToCloseNoInternet}
                      iconMapping={{
                        success: (
                          <ReportProblemOutlinedIcon fontSize="inherit" />
                        ),
                      }}
                    >
                      <AlertTitle className="alert-title">
                        {this.props.noInternet !== undefined
                          ? "No Internet"
                          : "Update Error"}
                      </AlertTitle>
                      <span className="alert-des">
                        {this.props.noInternet !== undefined
                          ? "There is no Internet. Please reconnect to keep monitoring your energy equipment."
                          : "There is no Internet. Please reconnect to keep monitoring your energy equipment."}
                      </span>
                    </BlackOutlinedErrorAlert>
                  </div>
                </div>
              )}
            <div className="col pl-4">
              <Header />
              <div className="KPIWidgets">
                <div className="row" style={{ gap: "1%", marginRight: "0px" }}>
                  <div className="col kpiView">
                    <div className="row">
                      <div className="col-md-3">
                        <img src={HomeWork} alt="" title={"HomeWork"} />
                      </div>
                      <div class="col-md-9 roboto-flex-uniquifier-title">
                        Site Status
                        <div
                          className="roboto-flex-uniquifier"
                          style={{ height: "18px" }}
                        >
                          Online Sites: {formInput.totalOnSite}
                        </div>
                        <span className="roboto-flex-uniquifier">
                          Offline Sites: {formInput.totalOffSite}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="col kpiView">
                    <div style={{ height: "100%" }}>
                      <div class="row">
                        <div className="col-md-2">
                          <img src={Warning} title={"Warning"} alt="" />
                        </div>
                        <div className="col-md-10 roboto-flex-uniquifier-title">
                          Site with a Warning
                          <div className="roboto-flex-uniquifier">
                            {formInput.totalWarning} Sites to check
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col kpiView">
                    <div style={{ height: "100%" }}>
                      <div class="row">
                        <div className="col-md-2">
                          <img
                            src={energyGeneration}
                            title={"energy Generation"}
                            alt=""
                          />
                        </div>
                        <div className="col-md-10 roboto-flex-uniquifier-title">
                          Total Power Production
                          <div className="roboto-flex-uniquifier">
                            {formInput.totalPowerEnergy !== undefined
                              ? formInput.totalPowerEnergy % 1 !== 0
                                ? formInput.totalPowerEnergy?.toFixed(2) + " kW"
                                : formInput.totalPowerEnergy.toFixed(0) + " kW"
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col kpiView">
                    <div style={{ height: "100%" }}>
                      <div class="row">
                        <div className="col-md-2">
                          <img
                            src={energyConsumption}
                            title={"energy Consumption"}
                            alt=""
                          />
                        </div>
                        <div className="col-md-10 roboto-flex-uniquifier-title">
                          Total Power Drawn
                          <div className="roboto-flex-uniquifier">
                            {formInput.totalPowerConsumption !== undefined
                              ? Math.abs(formInput.totalPowerConsumption) %
                                  1 !==
                                0
                                ? Math.abs(
                                    formInput.totalPowerConsumption
                                  ).toFixed(2) + " kW"
                                : Math.abs(
                                    formInput.totalPowerConsumption
                                  ).toFixed(0) + " kW"
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="SitesWidgets">
                <div className="sitesOverview" style={{ background: "white" }}>
                  <div className="sitesHeader">Sites</div>
                  <div class="table-container">
                    <table
                      className="table table-hover"
                      style={{ marginBottom: "0px" }}
                    >
                      <thead className="table-header">
                        <tr>
                          <th scope="col" style={{ width: "4%" }}></th>
                          <th
                            scope="col"
                            className="roboto-flex-uniquifier-title"
                          >
                            Site Name
                          </th>
                          <th
                            className="roboto-flex-uniquifier-title"
                            scope="col"
                            style={{ width: "10%" }}
                          >
                            Status
                          </th>
                          <th
                            scope="col"
                            className="roboto-flex-uniquifier-title"
                          >
                            {" "}
                            Mode{" "}
                          </th>
                          <th
                            scope="col"
                            className="roboto-flex-uniquifier-title"
                          >
                            Location
                          </th>
                          <th
                            scope="col"
                            className="roboto-flex-uniquifier-title"
                          >
                            Power Production
                          </th>
                          <th
                            scope="col"
                            className="roboto-flex-uniquifier-title"
                          >
                            Power Drawn
                          </th>
                          <th
                            scope="col"
                            className="roboto-flex-uniquifier-title"
                          >
                            Last Seen
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table-body">
                        {this.props.projectAllData?.items.length > 0 &&
                        this.props.projectAllData?.items.sort((a, b) =>
                          a.label.localeCompare(b.label)
                        )
                          ? this.props.projectAllData?.items.map((data) => (
                              <tr
                                onClick={() => {
                                  this.checkSiteDetails(data.id_group);
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                <td style={{ width: "4%" }}></td>
                                <td className="roboto-flex-uniquifier">
                                  {data.label}
                                </td>
                                <td
                                  className="roboto-flex-uniquifier"
                                  style={{ width: "10%" }}
                                >
                                  {data?.physicalDevices.filter(
                                    (m) => m?.id_parent === null
                                  ).length > 0 ? (
                                    data?.physicalDevices.filter(
                                      (m) => m?.is_connected === 1
                                    ).length > 0 &&
                                    data?.physicalDevices.filter(
                                      (m) => m?.is_connected === 0
                                    ).length === 0 ? (
                                      <FontAwesomeIcon
                                        className="fa-lg successIcon"
                                        icon={faCircleCheck}
                                        style={{ marginLeft: "5%" }}
                                      />
                                    ) : data?.physicalDevices.filter(
                                        (m) => m?.is_connected === 1
                                      ).length > 0 &&
                                      data?.physicalDevices.filter(
                                        (m) => m?.is_connected === 0
                                      ).length > 0 ? (
                                      <FontAwesomeIcon
                                        className="fa-lg alertIcon"
                                        icon={faTriangleExclamation}
                                        style={{ marginLeft: "5%" }}
                                      />
                                    ) : data?.physicalDevices.filter(
                                        (m) => m?.is_connected === 1
                                      ).length === 0 &&
                                      data?.physicalDevices.filter(
                                        (m) => m?.is_connected === 0
                                      ).length > 0 ? (
                                      <FontAwesomeIcon
                                        className="fa-lg cancelShowIcon"
                                        icon={faTimesCircle}
                                        style={{ marginLeft: "5%" }}
                                      />
                                    ) : (
                                      <FontAwesomeIcon
                                        className="fa-lg cancelShowIcon"
                                        icon={faTimesCircle}
                                        style={{ marginLeft: "5%" }}
                                      />
                                    )
                                  ) : (
                                    <FontAwesomeIcon
                                      className="fa-lg cancelShowIcon"
                                      icon={faTimesCircle}
                                      style={{ marginLeft: "5%" }}
                                    />
                                  )}
                                </td>
                                <td className="roboto-flex-uniquifier">
                                  {data?.groupMonitor?.map((monitor, index) => (
                                    <div key={index}>
                                      {monitor?.json_configuration
                                        ?.wattMaestroMode && (
                                        <span>
                                          Inverter:{" "}
                                          {getWattMaestroModeText(
                                            monitor.json_configuration
                                              .wattMaestroMode
                                          )}
                                        </span>
                                      )}
                                      {monitor?.json_configuration
                                        ?.batteryMode && (
                                        <span>
                                          Battery:{" "}
                                          {getBatteryModeText(
                                            monitor.json_configuration
                                              .batteryMode
                                          )}
                                        </span>
                                      )}
                                    </div>
                                  ))}
                                </td>
                                <td className="roboto-flex-uniquifier">
                                  {data?.location === null
                                    ? "null"
                                    : data?.location?.short_address === null ||
                                      data?.location?.short_address ===
                                        undefined
                                    ? "null"
                                    : data?.location?.short_address}
                                </td>
                                <td className="roboto-flex-uniquifier">
                                  {data?.latestSummary
                                    ?.SplitEnergyMeterPowerPositive?.sum !==
                                  undefined
                                    ? data.latestSummary
                                        .SplitEnergyMeterPowerPositive.sum %
                                        1 !==
                                      0
                                      ? data.latestSummary.SplitEnergyMeterPowerPositive.sum.toFixed(
                                          2
                                        ) + " kW"
                                      : data.latestSummary.SplitEnergyMeterPowerPositive.sum.toFixed(
                                          0
                                        ) + " kW"
                                    : "-"}
                                </td>
                                <td className="roboto-flex-uniquifier">
                                  {data?.latestSummary
                                    ?.SplitEnergyMeterPowerNegative?.sum !==
                                  undefined
                                    ? Math.abs(
                                        data.latestSummary
                                          .SplitEnergyMeterPowerNegative.sum
                                      ) %
                                        1 !==
                                      0
                                      ? Math.abs(
                                          data.latestSummary
                                            .SplitEnergyMeterPowerNegative.sum
                                        ).toFixed(2) + " kW"
                                      : Math.abs(
                                          data.latestSummary
                                            .SplitEnergyMeterPowerNegative.sum
                                        ).toFixed(0) + " kW"
                                    : "-"}
                                </td>
                                <td className="roboto-flex-uniquifier">
                                  {data?.meta?.last_data_at === null ? (
                                    "N/A"
                                  ) : (
                                    <>
                                      {this.calculateTimeAgo(
                                        data?.meta?.last_data_at
                                      )}
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))
                          : ""}{" "}
                      </tbody>
                      {/* </div> */}
                    </table>
                  </div>
                </div>
              </div>
              <div className="scroll-topbar">
                <div className="scroll-container">
                  <div className="mobileWidgets">
                    <div className="row kpiWidgets">
                      <div className="row pb-2">
                        <div className="col-2">
                          <img src={HomeWork} alt="" title={"HomeWork"} />
                        </div>
                        <div class="col-5 roboto-flex-uniquifier-title">
                          Site Status
                        </div>
                        <div className="col-5 roboto-flex-uniquifier">
                          Online Sites: {formInput.totalOnSite}
                          <br />
                          Offline Sites: {formInput.totalOffSite}
                        </div>
                      </div>
                      <hr></hr>
                      <div class="row pb-2">
                        <div className="col-2">
                          <img src={Warning} title={"Warning"} alt="" />
                        </div>
                        <div className="col-5 roboto-flex-uniquifier-title">
                          Site with a Warning
                        </div>
                        <div className="col-5 roboto-flex-uniquifier">
                          {formInput.totalWarning} Sites to check
                        </div>
                      </div>
                      <hr></hr>
                      <div class="row pb-2">
                        <div className="col-2">
                          <img
                            src={energyGeneration}
                            title={"energy Generation"}
                            alt=""
                          />
                        </div>
                        <div className="col-5 roboto-flex-uniquifier-title">
                          Total Power Production
                        </div>
                        <div className="col-5 roboto-flex-uniquifier">
                          {formInput.totalPowerEnergy !== undefined
                            ? formInput.totalPowerEnergy % 1 !== 0
                              ? formInput.totalPowerEnergy?.toFixed(2) + " kW"
                              : formInput.totalPowerEnergy.toFixed(0) + " kW"
                            : "N/A"}
                        </div>
                      </div>
                      <hr></hr>
                      <div class="row">
                        <div className="col-2">
                          <img
                            src={energyConsumption}
                            title={"energy Consumption"}
                            alt=""
                          />
                        </div>
                        <div className="col-5 roboto-flex-uniquifier-title">
                          Total Power Drawn
                        </div>
                        <div className="col-5 roboto-flex-uniquifier">
                          {formInput.totalPowerConsumption !== undefined
                            ? Math.abs(formInput.totalPowerConsumption) % 1 !==
                              0
                              ? Math.abs(
                                  formInput.totalPowerConsumption
                                ).toFixed(2) + " kW"
                              : Math.abs(
                                  formInput.totalPowerConsumption
                                ).toFixed(0) + " kW"
                            : "N/A"}
                        </div>
                      </div>
                    </div>
                  </div>
                  {this.props.projectAllData?.items.length > 0
                    ? this.props.projectAllData?.items.map((data) => (
                        <table
                          class="table SitesMobileWidgets table-borderless"
                          onClick={() => {
                            this.checkSiteDetails(data.id_group);
                          }}
                        >
                          <div className="table-mobile-widgets">
                            <div className="kpiMobileWidgets">
                              <tbody>
                                <tr>
                                  <th scope="row" style={{ width: "10%" }}>
                                    {data?.physicalDevices.filter(
                                      (m) => m?.id_parent === null
                                    ).length > 0 ? (
                                      data?.physicalDevices.filter(
                                        (m) => m?.is_connected === 1
                                      ).length > 0 &&
                                      data?.physicalDevices.filter(
                                        (m) => m?.is_connected === 0
                                      ).length === 0 ? (
                                        <FontAwesomeIcon
                                          className="fa-lg successIcon"
                                          icon={faCircleCheck}
                                          style={{ marginLeft: "5%" }}
                                        />
                                      ) : data?.physicalDevices.filter(
                                          (m) => m?.is_connected === 1
                                        ).length > 0 &&
                                        data?.physicalDevices.filter(
                                          (m) => m?.is_connected === 0
                                        ).length > 0 ? (
                                        <FontAwesomeIcon
                                          className="fa-lg alertIcon"
                                          icon={faTriangleExclamation}
                                          style={{ marginLeft: "5%" }}
                                        />
                                      ) : data?.physicalDevices.filter(
                                          (m) => m?.is_connected === 1
                                        ).length === 0 &&
                                        data?.physicalDevices.filter(
                                          (m) => m?.is_connected === 0
                                        ).length > 0 ? (
                                        <FontAwesomeIcon
                                          className="fa-lg cancelShowIcon"
                                          icon={faTimesCircle}
                                          style={{ marginLeft: "5%" }}
                                        />
                                      ) : (
                                        <FontAwesomeIcon
                                          className="fa-lg cancelShowIcon"
                                          icon={faTimesCircle}
                                          style={{ marginLeft: "5%" }}
                                        />
                                      )
                                    ) : (
                                      <FontAwesomeIcon
                                        className="fa-lg cancelShowIcon"
                                        icon={faTimesCircle}
                                        style={{ marginLeft: "5%" }}
                                      />
                                    )}
                                  </th>
                                  <td colspan="2">
                                    <div className="roboto-flex-uniquifier-title">
                                      {data.label}
                                    </div>
                                  </td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <th scope="row"></th>
                                  <td>
                                    <div className="roboto-flex-uniquifier">
                                      Mode:
                                    </div>
                                  </td>
                                  <td>
                                    {" "}
                                    <div
                                      className="roboto-flex-uniquifier"
                                      style={{ paddingLeft: "3%" }}
                                    >
                                      {data?.groupMonitor?.map(
                                        (monitor, index) => (
                                          <div key={index}>
                                            {monitor?.json_configuration
                                              ?.wattMaestroMode && (
                                              <span>
                                                Inverter:{" "}
                                                {getWattMaestroModeText(
                                                  monitor.json_configuration
                                                    .wattMaestroMode
                                                )}
                                              </span>
                                            )}
                                            {monitor?.json_configuration
                                              ?.batteryMode && (
                                              <span>
                                                Battery:{" "}
                                                {getBatteryModeText(
                                                  monitor.json_configuration
                                                    .batteryMode
                                                )}
                                              </span>
                                            )}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row"></th>
                                  <td>
                                    <div className="roboto-flex-uniquifier">
                                      Location:
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="roboto-flex-uniquifier"
                                      style={{
                                        paddingLeft: "3%",
                                        width: "150px",
                                      }}
                                    >
                                      {data?.location === null
                                        ? "null"
                                        : data?.location?.short_address ===
                                            null ||
                                          data?.location?.short_address ===
                                            undefined
                                        ? "null"
                                        : data?.location?.short_address}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row"></th>
                                  <td>
                                    <div className="roboto-flex-uniquifier">
                                      Power Production:
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="roboto-flex-uniquifier"
                                      style={{ paddingLeft: "3%" }}
                                    >
                                      {data?.latestSummary
                                        ?.SplitEnergyMeterPowerPositive?.sum !==
                                      undefined
                                        ? data.latestSummary
                                            .SplitEnergyMeterPowerPositive.sum %
                                            1 !==
                                          0
                                          ? data.latestSummary.SplitEnergyMeterPowerPositive.sum.toFixed(
                                              2
                                            ) + " kW"
                                          : data.latestSummary.SplitEnergyMeterPowerPositive.sum.toFixed(
                                              0
                                            ) + " kW"
                                        : "-"}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row"></th>
                                  <td>
                                    <div className="roboto-flex-uniquifier">
                                      Power Drawn:
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="roboto-flex-uniquifier"
                                      style={{ paddingLeft: "3%" }}
                                    >
                                      {data?.latestSummary
                                        ?.SplitEnergyMeterPowerNegative?.sum !==
                                      undefined
                                        ? Math.abs(
                                            data.latestSummary
                                              .SplitEnergyMeterPowerNegative.sum
                                          ) %
                                            1 !==
                                          0
                                          ? Math.abs(
                                              data.latestSummary
                                                .SplitEnergyMeterPowerNegative
                                                .sum
                                            ).toFixed(2) + " kW"
                                          : Math.abs(
                                              data.latestSummary
                                                .SplitEnergyMeterPowerNegative
                                                .sum
                                            ).toFixed(0) + " kW"
                                        : "-"}
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row"></th>
                                  <td>
                                    <div className="roboto-flex-uniquifier">
                                      Last Seen:
                                    </div>
                                  </td>
                                  <td>
                                    <div
                                      className="roboto-flex-uniquifier"
                                      style={{ paddingLeft: "3%" }}
                                    >
                                      {data?.meta?.last_data_at === null ? (
                                        "N/A"
                                      ) : (
                                        <>
                                          {this.calculateTimeAgo(
                                            data?.meta?.last_data_at
                                          )}
                                        </>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </div>
                          </div>
                        </table>
                      ))
                    : ""}{" "}
                </div>
              </div>
            </div>
          </div>
          {this.props.projectAllData?.items.length === 0 && (
            <div className="noProject">
              No site is assigned to your account yet, please contact your
              representative.
            </div>
          )}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          <div style={{ height: "600px" }}>
            <div className="col pl-4">
              <div className="loginImgDiv">
                <div style={{ display: "inline" }}>
                  {" "}
                  <div className="wattTitle">
                    <div>WattMaestro</div>
                  </div>
                </div>
              </div>
              <div
                className="wattMaestro-lg"
                style={{ width: "90%", margin: "auto" }}
              >
                <LoaderRoller />
              </div>
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    userEmail: state.userReducer?.user?.email,
    topUserPopup: state.screenActivitiesReducer?.topUserPopup,
    noInternet: state.listViewReducer?.noInternet,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    openUserPopup,
    closeUserPopup,
    clearNoInternet,
  })(Sites)
);
