import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import ReactApexChart from "react-apexcharts";

class batteryChart extends Component {
  state = {
    lastSeenUpdate: false,
    alertVisible: false,
    series: [],
    options: [],
  };

  render() {
    const isMobile = window.innerWidth < 768;

    const batteryData = this.props.allChartData?.items.filter(
      (n) => n.id_container_data_latest === this.props.batteryDataSets[0]
    );

    const energyData = this.props.allChartData?.items.filter(
      (n) => n.id_container_data_latest === this.props.energyDataSets[0]
    );
    const dataBatteryMonitorModeLength =
      this.props.dataBatteryMonitorMode.length > 0 ? true : false;
    const dataBatteryOptimizationModeLength =
      this.props.dataBatteryOptimizationMode.length > 0 ? true : false;

    const labels =
      batteryData?.map((dataPoint) => dataPoint?.timestamp_seconds * 1000) ||
      [];
    const seriesArray = [];

    if (dataBatteryMonitorModeLength || dataBatteryOptimizationModeLength) {
      if (!batteryData) {
        return [];
      }
      const seriesData = batteryData.map((item) => {
        return isNaN(item.avg_val) || item.avg_val == null ? 0 : item.avg_val;
      });

      seriesArray.push({
        name: "State of Charge",
        data: seriesData,
        type: "line",
        color: "#DC143C",
      });

      if (dataBatteryOptimizationModeLength) {
        if (!energyData) {
          return [];
        }
        const seriesEnergyData = this.props.energyDataSets.map(() => {
          return energyData.map((item) => {
            return isNaN(item.avg_val) || item.avg_val == null
              ? 0
              : item.avg_val;
          });
        });

        seriesArray.push({
          name: "Energy Price",
          data: seriesEnergyData[0],
          type: "line",
          color: "#1FBA8E",
        });
      }
    }

    const options = {
      tooltip: {
        enabled: true,
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const seriesColor = seriesIndex === 0 ? "#DC143C" : "#1FBA8E"; // Get the color of the series
          const unit = seriesIndex === 0 ? "%" : "€"; // Get the unit for the series
          const value = series[seriesIndex][dataPointIndex]; // Get the current data point value

          return `
      <div class="custom-tooltip" style="background-color: ${seriesColor}; padding: 5px; border-radius: 5px;">
        <span style="color: white;">
          ${unit} ${
            value !== undefined
              ? Number(value) % 1 !== 0
                ? Number(value).toFixed(4)
                : Number(value).toFixed(0)
              : "N/A"
          }
        </span>
      </div>
    `;
        },
      },
      chart: {
        height: 750,
        type: "line",
        toolbar: {
          show: false,
          tools: {
            download: false,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      noData: {
        fontSize: "16px",
        text: "No data available",
        align: "center",
        verticalAlign: "middle",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#DC143C", "#1FBA8E"],
      title: {
        text: dataBatteryOptimizationModeLength
          ? "Battery State of Charge, Energy Price"
          : "Battery State of Charge",
        align: "left",
        style: {
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "700",
          fontFamily: "Roboto Flex, sans-serif",
        },
        floating: false,
      },
      legend: {
        show: true,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        fontFamily: "Roboto Flex",
        fontSize: "14px",
        fontWeight: "100",
        height: 40,
        labels: {
          colors: ["#DC143C", "#1FBA8E"],
        },
        markers: {
          width: 0,
          height: 0,
          radius: 0,
        },
        formatter: (seriesName, opts) => {
          const color = opts.w.globals.colors[opts.seriesIndex];
          const col = "#fff";
          const displayName = seriesName;
          return `<span class="legend-item" style="background-color: ${color};color: ${col};">${displayName}</span>`;
        },
      },
      xaxis: {
        tooltip: {
          enabled: true,
        },
        categories: labels,
        type: "datetime",
        tickPlacement: "on",
        labels: {
          datetimeUTC: false,
          showDuplicates: false,
        },
      },
      yaxis: dataBatteryOptimizationModeLength
        ? [
            {
              show: true,
              labels: {
                formatter: (value) => {
                  return Number.isInteger(value)
                    ? value?.toFixed(0)
                    : value?.toFixed(2);
                },
              },
              title: {
                text: "%",
                rotate: -90,
                offsetX: isMobile ? 10 : 0,
                offsetY: 0,
                style: {
                  // color: "#DC143C",
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 700,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
            },
            {
              opposite: true,
              title: {
                text: "€",
                rotate: -90,
                offsetX: isMobile ? -10 : 0,
                offsetY: 0,
                style: {
                  //  color: "#1FBA8E",
                  fontSize: "12px",
                  fontFamily: "Helvetica, Arial, sans-serif",
                  fontWeight: 700,
                  cssClass: "apexcharts-yaxis-title",
                },
              },
              labels: {
                formatter: function (value) {
                  return value?.toFixed(2);
                },
              },
            },
          ]
        : {
            show: true,
            labels: {
              formatter: (value) => {
                return Number.isInteger(value)
                  ? value?.toFixed(0)
                  : value?.toFixed(2);
              },
            },
            title: {
              text: "%",
              rotate: -90,
              offsetX: isMobile ? 10 : 0,
              offsetY: 0,
              style: {
                //  color: "#DC143C",
                fontSize: "12px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 700,
                cssClass: "apexcharts-yaxis-title",
              },
            },
          },
    };

    return (
      <div>
        <ReactApexChart
          options={options}
          series={seriesArray}
          type="line"
          height={300}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
  };
}

export default withRouter(connect(mapStateToProps, {})(batteryChart));
