import React, { Component } from "react";

import "./style.css";

export default class LoaderRoller extends Component {
  render() {
    return (
      <div className="d-flex justify-content-center">
        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
