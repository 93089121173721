import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import EnergyMeter from "../../../assets/watt-icons/energy_meter.svg";
import GridMode from "../../../assets/watt-icons/grid.svg";
import EquipmentStatus from "../../../assets/watt-icons/equipment_status.svg";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import {
  siteModeUpdate,
  clearSiteModeChange,
} from "../../../actions/list-view";
import "./styles.css";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import EnergyChartView from "../../chart/EnergyChartView";
import Homechartfilter from "../../chart/homechartfilter";
import TinySpinner from "../../../components/loader/TinySpinner";
import LastSeenStatus from "../../chart/lastSeenStatus";
import LoaderRoller from "../../../components/loader/LoaderRoller";

class energy extends Component {
  state = {
    formInput: {
      siteModechange: false,
      siteModeNameValue: "",
      loadBalanceValue: "",
      wattMaestroMode: "",
      sitelabel: "",
      idGroup: "",
      classNameView: "",
      source_device: "",
      alertSiteModeVisible: false,
      monitorGroup: "",
      dataSetsArray: [],
      chartName: [],
      chartUnit: [],
    },
  };
  handleToChangeSite = () => {
    this.props.clearSiteModeChange();
    const formInput = { ...this.state.formInput };
    formInput.siteModechange = true;
    formInput.alertSiteModeVisible = false;
    this.setState({ formInput });
  };

  handleToChangeSiteModeClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.siteModechange = false;
    this.setState({ formInput });
  };
  handleSiteNameChange = (e) => {
    const selectedOption = e.target.getAttribute("value");
    const formInput = { ...this.state.formInput };
    // formInput[input.name] = input.value;
    formInput.siteModeNameValue = selectedOption;
    formInput.loadBalanceValue = "";
    this.setState({ formInput });
  };

  handleLoadBalanceValueChange = ({ currentTarget: input }) => {
    const formInput = { ...this.state.formInput };
    formInput[input.name] = input.value;
    formInput.loadBalanceValue = input.value;
    this.setState({ formInput });
  };
  handleFilterData = (data) => {
    this.setState((prevState) => ({
      formInput: {
        ...prevState.formInput,
        ...data,
      },
    }));
  };
  handleSiteModeSubmitClick = () => {
    this.props.clearSiteModeChange();
    let { groupId } = this.props.match.params;
    const formInput = { ...this.state.formInput };
    const siteModeRestDate = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    )[0].groupMonitor;
    const sourceDeviceId = this.props.allProjectData?.items
      .filter((n) => n.id_group === groupId)[0]
      .physicalDevices.filter(
        (m) => m?.id_parent === null
      )[0].id_physical_device;
    formInput.wattMaestroMode =
      formInput.siteModeNameValue === ""
        ? siteModeRestDate?.json_configuration?.wattMaestroMode
        : formInput.siteModeNameValue === "Monitor only"
        ? 1
        : formInput.siteModeNameValue === "Curtailment"
        ? 2
        : 3;
    formInput.sitelabel = siteModeRestDate?.label;
    formInput.idGroup = siteModeRestDate?.id_group;
    formInput.monitorGroup = siteModeRestDate?.id_monitor_rule;
    formInput.source_device = sourceDeviceId;
    formInput.alertSiteModeVisible = true;
    formInput.siteModechange = false;
    this.setState({ formInput });
    this.props.siteModeUpdate(formInput, groupId);
  };

  handleToSiteModeClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.alertSiteModeVisible = false;
    formInput.siteModechange = false;
    this.setState({ formInput, alertVisible: false });
    this.props.clearSiteModeChange();
  };
  handleToClose = () => {
    const formInput = { ...this.state.formInput };
    formInput.lastSeenUpdate = false;
    this.setState({ formInput, alertVisible: false });
  };

  render() {
    const formInput = { ...this.state.formInput };
    let { groupId } = this.props.match.params;
    const KPIdetails = this.props.monitorDetails[0]?.deviceProfile;
    const dataKPI = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const monitorData = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    var energyData = [];
    let dataSet = [];
    let dataName = [];
    let dataUnit = [];
    Object.keys(dataKPI[0]?.latestSummary).forEach((key) => {
      const dataCategory = dataKPI[0]?.latestSummary[key].data_category;
      if (
        dataCategory === "ActiveImportEnergy" ||
        dataCategory === "ActiveExportEnergy" ||
        dataCategory === "EnergyPrice"
      ) {
        energyData.push(dataKPI[0]?.latestSummary[key]);
        //for filter
        const datasetName = dataKPI[0]?.latestSummary[key].datasets;
        const chartName = dataKPI[0]?.latestSummary[key].data_category;
        const chatUnit = dataKPI[0]?.latestSummary[key].unit;
        dataSet.push(datasetName);
        dataName.push(chartName);
        dataUnit.push(chatUnit);
      }
    });

    dataSet.forEach((m, index) => {
      formInput.dataSetsArray[index] = m[0];
    });
    dataName.forEach((p, index) => {
      formInput.chartName[index] = p;
    });
    dataUnit.forEach((n, index) => {
      formInput.chartUnit[index] = n;
    });
    const physicalDevices = dataKPI[0]?.physicalDevices;
    const powerExportdata = dataKPI[0]?.latestSummary?.ActiveExportEnergy?.sum;
    const powerExportUnitData =
      dataKPI[0]?.latestSummary?.ActiveExportEnergy?.unit;
    const powerImportdata = dataKPI[0]?.latestSummary?.ActiveImportEnergy?.sum;
    const powerImportUnitData =
      dataKPI[0]?.latestSummary?.ActiveImportEnergy?.unit;
    const voltageData = dataKPI[0]?.latestSummary?.VoltageL1N?.sum.toFixed(2);
    const voltageUnitData = dataKPI[0]?.latestSummary?.VoltageL1N?.unit;
    const currentData = dataKPI[0]?.latestSummary?.CurrentL1N?.sum.toFixed(2);
    const currentUnitData = dataKPI[0]?.latestSummary?.CurrentL1N?.unit;
    const frequencyData = dataKPI[0]?.latestSummary?.Frequency?.sum.toFixed(2);
    const frequencyUnitData = dataKPI[0]?.latestSummary?.Frequency?.unit;
    formInput.siteModeNameValue =
      formInput.siteModeNameValue === ""
        ? String(
            dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode
          ) === "1"
          ? "Monitor only"
          : String(
              dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode
            ) === "2"
          ? "Curtailment"
          : String(
              dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode
            ) === "3"
          ? "Load Balancing"
          : "-"
        : formInput.siteModeNameValue;
    const siteModeValue =
      String(dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode) ===
      "1"
        ? "Monitor only"
        : String(
            dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode
          ) === "2"
        ? "Curtailment"
        : String(
            dataKPI[0]?.groupMonitor?.json_configuration?.wattMaestroMode
          ) === "3"
        ? "Load Balancing"
        : "-";

    const BlackOutlinedErrorAlert = styled(Alert)({
      "& .MuiAlert-icon": {
        color: "#000000",
        marginTop: "3px",
      },
    });
    const getLatestData = (physicalDevices) => {
      if (
        physicalDevices.id_parent !== null &&
        physicalDevices.deviceProfile !== null
      ) {
        return physicalDevices.latestData;
      }
      return null;
    };

    return (
      <Fragment>
        <div className="filter">
          <LastSeenStatus
            equipmentStatusData={monitorData}
            className="lastSeen"
            filterOptions={this.props.filterOptions}
          />
          <Homechartfilter
            dataSetsArray={formInput.dataSetsArray}
            chartName={formInput.chartName}
            chartUnit={formInput.chartUnit}
            handleFilterData={this.handleFilterData}
          />
        </div>
        {formInput.siteModechange &&
          this.props.siteModeChanged === undefined && (
            <Dialog
              open={
                formInput.siteModechange === true &&
                this.props.siteModeChanged === undefined
                  ? true
                  : false
              }
              onClose={this.handleToChangeSiteModeClose}
              className="dialogSite"
            >
              <div onClick={this.handleToChangeSiteModeClose}>
                <FontAwesomeIcon
                  className="svg-icons-sm wifiView wifiFailVie"
                  data-toggle="tooltip"
                  data-placement="top"
                  icon={faTimes}
                  onClick={this.handleToChangeSiteModeClose}
                />
              </div>
              <DialogTitle>
                <div className="changeSiteModeHeader">Site Mode</div>
              </DialogTitle>
              <DialogContent>
                <div className="changeSiteModeTitle">
                  <div class="dropdown">
                    <button
                      class="dropdown-toggle dropdownSiteModeOptionStyle"
                      type="button"
                      id="dropdownSiteName"
                      data-bs-toggle="dropdown"
                    >
                      {formInput.siteModeNameValue
                        ? formInput.siteModeNameValue
                        : "Select an option"}
                    </button>
                    <div
                      class="dropdown-menu dropdownSiteStyle"
                      aria-labelledby="dropdownSiteName"
                    >
                      <button
                        class="dropdown-item optionValue"
                        value="Curtailment"
                        onClick={this.handleSiteNameChange}
                      >
                        Curtailment
                      </button>

                      <button
                        class="dropdown-item optionValue"
                        value="Monitor only"
                        onClick={this.handleSiteNameChange}
                      >
                        Monitor only
                      </button>
                    </div>
                  </div>
                </div>
                {/* Load Balancing code is hidden.Will be use in V1 */}
                <diiv>
                  {" "}
                  {formInput.siteModeNameValue === "Load Balancing" ? (
                    <div>
                      {" "}
                      <div className="changeSiteModeTitle">
                        {" "}
                        <input
                          name="name"
                          type="number"
                          value={formInput?.loadBalanceValue}
                          onChange={this.handleLoadBalanceValueChange}
                          className="siteModbusInput"
                          placeholder="kW"
                        />
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </diiv>
                <div className="buttonSiteModeView changeSiteModeTitle">
                  {" "}
                  <button
                    onClick={this.handleSiteModeSubmitClick}
                    className="submitSiteModeClick"
                  >
                    Confirm
                  </button>{" "}
                  <button
                    className="cancelSiteModeClick"
                    onClick={this.handleToChangeSiteModeClose}
                  >
                    Cancel
                  </button>
                </div>
              </DialogContent>
            </Dialog>
          )}
        <div class="KPIEnergyWidgets">
          <div class="row" style={{ gap: "1%", marginRight: "0px" }}>
            <div class="col kpiView kpiAlign">
              <div style={{ height: "100%" }}>
                <div class="row">
                  <div className="col-md-2">
                    <img
                      src={EquipmentStatus}
                      alt=""
                      title={"Equipment Status"}
                    />
                  </div>
                  <div className="col-md-10 roboto-flex-uniquifier-title">
                    <div style={{ display: "flex" }}>
                      {" "}
                      Equipment Status
                      <b
                        className={
                          this.props.monitorDetails[0]?.is_connected === 0
                            ? "OfflineEquipment"
                            : "OnlineEquipment"
                        }
                      >
                        {this.props.monitorDetails[0]?.is_connected === 0
                          ? "Offline"
                          : "Online"}
                      </b>
                    </div>
                    <div className="roboto-flex-uniquifier">
                      {" "}
                      Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col kpiView">
              <div style={{ height: "100%" }}>
                <div class="row">
                  <div className="col-md-2">
                    <img src={GridMode} title={"Grid Mode"} alt="" />
                  </div>
                  <div class="col-md-10 roboto-flex-uniquifier-title">
                    Grid Net
                    <div
                      className="roboto-flex-uniquifier"
                      style={{ height: "18px" }}
                    >
                      Power Import:{" "}
                      {powerImportdata !== null && powerImportdata !== undefined
                        ? parseFloat(powerImportdata) !== 0
                          ? parseFloat(powerImportdata).toFixed(2) +
                            " " +
                            powerImportUnitData
                          : powerImportdata + " " + powerImportUnitData
                        : "N/A"}
                    </div>
                    <span className="roboto-flex-uniquifier">
                      Power Export:{" "}
                      {powerExportdata !== null && powerExportdata !== undefined
                        ? parseFloat(powerExportdata) !== 0
                          ? parseFloat(powerExportdata).toFixed(2) +
                            " " +
                            powerExportUnitData
                          : powerExportdata + " " + powerExportUnitData
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/* <div class="col kpiView">
              <div style={{ height: "100%", width: "100%" }}>
                <div class="row">
                  <div className="col-md-2">
                    <img src={SiteMode} title={"Site Mode"} alt="" />
                  </div>
                  <div className="col-md-7 roboto-flex-uniquifier-title">
                    Site Mode{" "}
                    <div className="roboto-flex-uniquifier">
                      {siteModeValue !== null && siteModeValue !== undefined
                        ? siteModeValue
                        : "N/A"}
                    </div>
                  </div>
                  <div
                    className="col-md-3 roboto-flex-uniquifier-title"
                    onClick={this.handleToChangeSite}
                  >
                    {userTypeCheck !== 10 &&
                      this.props.allProjectData?.items.filter(
                        (n) => n.id_group === groupId
                      )[0].groupMonitor !== null && (
                        <img
                          src={siteModeEdit}
                          alt=""
                          title={"Equipment Status"}
                          className="siteIcon"
                        />
                      )}
                  </div>
                </div>
              </div>
            </div> */}

            <div class="col kpiView">
              <div style={{ height: "100%" }}>
                <div class="row">
                  <div className="col-md-2">
                    <img src={EnergyMeter} title={"energy Meter"} alt="" />
                  </div>
                  <div class="col-md-9 roboto-flex-uniquifier-title">
                    Other Parameters
                    <div
                      className="roboto-flex-uniquifier"
                      style={{ height: "18px" }}
                    >
                      Voltage:{" "}
                      {voltageData !== null && voltageData !== undefined
                        ? voltageData + " " + voltageUnitData
                        : "N/A"}
                    </div>
                    <div
                      className="roboto-flex-uniquifier"
                      style={{ height: "18px" }}
                    >
                      Current:{" "}
                      {voltageData !== null && voltageData !== undefined
                        ? currentData + " " + currentUnitData
                        : "N/A"}
                    </div>
                    <div
                      className="roboto-flex-uniquifier"
                      style={{ height: "18px" }}
                    >
                      Frequency:{" "}
                      {voltageData !== null && voltageData !== undefined
                        ? frequencyData + " " + frequencyUnitData
                        : "N/A"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* mobile view kpi widgets */}
        <div className="mobile-kpi-widgets">
          <div className="kpiView mb-2-mobile d-flex align-items-start">
            <div style={{ marginRight: "12px" }}>
              <img src={EquipmentStatus} alt="" title={"Equipment Status"} />{" "}
            </div>
            <div className="roboto-flex-uniquifier-title">
              {" "}
              Equipment Status
              <div className="roboto-flex-uniquifier">
                Brand: {KPIdetails?.manufacturer} - {KPIdetails?.model}
              </div>
            </div>{" "}
            <b
              className={
                this.props.monitorDetails[0]?.is_connected === 0
                  ? "OfflineEquipment"
                  : "OnlineEquipment"
              }
            >
              {this.props.monitorDetails[0]?.is_connected === 0
                ? "Offline"
                : "Online"}
            </b>
          </div>
          <div className="kpiView mb-2-mobile d-flex align-items-start">
            {" "}
            <div style={{ marginRight: "12px" }}>
              <img src={GridMode} title={"Grid Mode"} alt="" />
            </div>
            <div class="col-md-10 roboto-flex-uniquifier-title">
              Grid Net
              <div
                className="roboto-flex-uniquifier"
                style={{ height: "18px" }}
              >
                Power Import:{" "}
                {powerImportdata !== null && powerImportdata !== undefined
                  ? parseFloat(powerImportdata) !== 0
                    ? parseFloat(powerImportdata).toFixed(2) +
                      " " +
                      powerImportUnitData
                    : powerImportdata + " " + powerImportUnitData
                  : "N/A"}
              </div>
              <span className="roboto-flex-uniquifier">
                Power Export:{" "}
                {powerExportdata !== null && powerExportdata !== undefined
                  ? parseFloat(powerExportdata) !== 0
                    ? parseFloat(powerExportdata).toFixed(2) +
                      " " +
                      powerExportUnitData
                    : powerExportdata + " " + powerExportUnitData
                  : "N/A"}
              </span>
            </div>
          </div>
          <div className="kpiView mb-2-mobile d-flex align-items-start">
            {" "}
            <div style={{ marginRight: "12px" }}>
              <img src={EnergyMeter} title={"energy Meter"} alt="" />
            </div>
            <div class="col-md-9 roboto-flex-uniquifier-title">
              Other Parameters
              <table class="table roboto-flex-uniquifier">
                <tbody>
                  <tr>
                    <td>Voltage:</td>
                    <td>
                      {voltageData !== null && voltageData !== undefined
                        ? voltageData + " " + voltageUnitData
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td> Current: </td>
                    <td>
                      {voltageData !== null && voltageData !== undefined
                        ? currentData + " " + currentUnitData
                        : "N/A"}
                    </td>
                  </tr>
                  <tr>
                    <td>Frequency:</td>
                    <td>
                      {" "}
                      {voltageData !== null && voltageData !== undefined
                        ? frequencyData + " " + frequencyUnitData
                        : "N/A"}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="chartSett">
          <div className="container-top-align">
            <div className="bacecard-graph-chart top-chart col-lg-8 box-shadow mb-2-mobile m-1">
              {energyData !== undefined && energyData !== null ? (
                <div>
                  {this.props.loader ? <TinySpinner /> : ""}
                  <EnergyChartView data={energyData} />
                </div>
              ) : (
                <div>No data available</div>
              )}
            </div>

            <div className="chartSetWidthParameter col-md-3 box-shadow">
              <div className="chartHeading">Energy Meter</div>

              <div className="row tableHeading">
                <div className="col-2 col-lg-1">#</div>
                <div className="col-5 col-lg-6">Label</div>
                <div className="col-5 col-lg-4">Value</div>
              </div>
              <div className="scrollContentEnergy">
                {physicalDevices.map((physicalDevice) => {
                  const latestData = getLatestData(physicalDevice);
                  const equipmentLabel =
                    physicalDevice.deviceProfile?.equipmentLabel;

                  if (
                    equipmentLabel &&
                    equipmentLabel === "Energy Meter" &&
                    latestData
                  ) {
                    const sortedData = latestData
                      .slice()
                      .sort((a, b) =>
                        a.label.trim().localeCompare(b.label.trim())
                      );

                    return sortedData.map((data, dataIndex) => (
                      <div
                        className="row tableDataText"
                        key={dataIndex}
                        style={{ marginLeft: "0px" }}
                      >
                        <div className="fullwidth col-2 col-lg-1">
                          {dataIndex + 1}
                        </div>
                        <div className="fullwidth col-5 col-lg-6">
                          {data.label}
                        </div>
                        <div className="fullwidth col-5 col-lg-4">
                          {data.value !== undefined
                            ? Number(data.value) % 1 !== 0
                              ? Number(data.value).toFixed(2)
                              : Number(data.value).toFixed(0)
                            : "N/A"}
                          {data.unit}
                        </div>
                      </div>
                    ));
                  }
                  return null;
                })}
              </div>
            </div>
          </div>
        </div>
        {this.props.siteModeChanged === undefined &&
          formInput.alertSiteModeVisible && (
            <Dialog
              open={
                this.props.siteModeChanged === undefined &&
                formInput.alertSiteModeVisible
                  ? true
                  : false
              }
              className="dialogSite"
            >
              <DialogTitle>
                <div style={{ fontWeight: "400" }}>
                  Site mode is updating from {siteModeValue} to{" "}
                  {formInput.siteModeNameValue}.
                </div>
              </DialogTitle>
              <DialogContent>
                <div className="changeSiteModeTitle">
                  <LoaderRoller />
                </div>
              </DialogContent>
            </Dialog>
          )}
        <div className="alertDialog" onClick={this.handleToSiteModeClose}>
          {this.props.siteModeChanged !== undefined &&
            formInput.alertSiteModeVisible && (
              <BlackOutlinedErrorAlert
                className={`${
                  this.props.siteModeChanged?.status === 200
                    ? "success-name-dialog"
                    : "error-name-dialog"
                }`}
                style={{
                  backgroundColor:
                    this.props.siteModeChanged?.status === 200
                      ? "#84CE66"
                      : "#FF5D5D",
                }}
                severity={
                  this.props.siteModeChanged?.status === 200
                    ? "success"
                    : "error"
                }
                onClose={this.handleToSiteModeClose}
                iconMapping={{
                  success: <CheckCircleOutlineIcon fontSize="inherit" />,
                }}
              >
                <AlertTitle
                  style={{
                    fontFamily: "'Roboto Flex', sans-serif",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  {this.props.siteModeChanged?.status === 200
                    ? "Site Mode changed"
                    : "Site Mode change failed"}
                </AlertTitle>
                {this.props.siteModeChanged?.status === 200
                  ? "Site Mode has been changed to " +
                    formInput?.siteModeNameValue
                  : "Site Mode failed to changed."}
              </BlackOutlinedErrorAlert>
            )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
    siteModeChanged: state.listViewReducer?.siteModeChanged,
    userEmail: state.userReducer?.user?.email,
    allChartData: state.listViewReducer?.allChartData,
    loader: state.listViewReducer?.loader,
  };
}

export default withRouter(
  connect(mapStateToProps, { siteModeUpdate, clearSiteModeChange })(energy)
);
