import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import "./styles.css";
import scatteredCloudsDay from "../../assets/weather/scattered-clouds-day.png";
import brokenCloudsDay from "../../assets/weather/broken-clouds-day.png";
import showerCloudsDay from "../../assets/weather/shower-clouds-day.png";
import clearSkyDay from "../../assets/weather/clear-sky-day.png";
import clearSkyNight from "../../assets/weather/clear-sky-night.png";
import Thunderstorm from "../../assets/weather/thunderstorm-clouds-day.png";
import fewClouds from "../../assets/weather/few-clouds-day.png";
import Mist from "../../assets/weather/mist-clouds-day.png";

class solarIrradiance extends Component {
  state = {};

  render() {
    let { groupId } = this.props.match.params;
    const data = this.props.allProjectData?.items.filter(
      (n) => n.id_group === groupId
    );
    const date = new Date(data[0]?.location?.updated_at * 1000);
    const hours = date.getHours();
    const ghiValue = data[0]?.weatherStatus?.current?.ghi;

    return (
      <div>
        <div className="d-flex">
          {" "}
          <div className="col-lg-11 col-12 bacecard-graph-chart top-chart weather-div">
            <div className="equipmentStatusHeading"> Solar Irradiance</div>
            <div className="location-view mt-2 mb-4">
              {" "}
              In
              {"   "}
              {data[0]?.location?.location_details?.address?.state} ,{" "}
              {data[0].location?.location_details?.address?.country}
            </div>
            <div className="weatherLine"></div>
            <table className="equipmentTable">
              <tbody>
                <tr className="weather-tr">
                  <td className="col-md-3">
                    {data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                    "scattered clouds" ? (
                      <img
                        src={scatteredCloudsDay}
                        title={"Scattered Clouds Day"}
                        alt=""
                        width={50}
                      />
                    ) : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                      "broken clouds" ? (
                      <img
                        src={brokenCloudsDay}
                        title={"Broken Clouds Day"}
                        alt=""
                        width={50}
                      />
                    ) : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                      "few clouds" ? (
                      <img
                        src={fewClouds}
                        title={"Few clouds Day"}
                        alt=""
                        width={50}
                      />
                    ) : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                      "fog" ? (
                      <img src={Mist} title={"fog day"} alt="" width={50} />
                    ) : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                      "thunderstorm" ? (
                      <img
                        src={Thunderstorm}
                        title={"Thunderstorm Day"}
                        alt=""
                        width={50}
                      />
                    ) : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                      "clear sky" ? (
                      hours >= 6 && hours < 18 ? (
                        <img
                          src={clearSkyDay}
                          title={"Clear Sky Day"}
                          alt=""
                          width={50}
                        />
                      ) : (
                        <img
                          src={clearSkyNight}
                          title={"Clear Sky"}
                          alt=""
                          width={50}
                        />
                      )
                    ) : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                      "overcast clouds" ? (
                      <img
                        src={brokenCloudsDay}
                        title={"Overcast Clouds Day"}
                        alt=""
                        width={50}
                      />
                    ) : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                        "light rain" ||
                      data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                        "moderate rain" ||
                      data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                        "shower rain" ||
                      data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                        "light shower rain" ? (
                      <img
                        src={showerCloudsDay}
                        title={
                          data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                          "light rain"
                            ? "Light Rain Day"
                            : data[0]?.weatherStatus?.current?.weather?.description.toLowerCase() ===
                              "shower rain"
                            ? "Shower Rain day"
                            : "Moderate Rain Day"
                        }
                        alt=""
                        width={50}
                      />
                    ) : (
                      ""
                    )}{" "}
                  </td>
                  <td className="col-md-3 p-2 location-view">
                    <div style={{ textAlign: "left" }}>
                      {ghiValue < 2001 && ghiValue >= 0
                        ? "Low"
                        : ghiValue < 3001 && ghiValue > 2000
                        ? "Moderate"
                        : ghiValue > 3000
                        ? "High"
                        : "N/A"}
                    </div>
                  </td>
                  <td className="col-md-5 p-2 weatherTemp">
                    {" "}
                    <span>
                      <b className="weatherTempDegree">
                        {Math.round(data[0]?.weatherStatus?.current?.ghi)} W/m²
                      </b>{" "}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allProjectData: state.listViewReducer?.allProjectData,
  };
}

export default withRouter(connect(mapStateToProps, {})(solarIrradiance));
